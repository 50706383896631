@import url("asset/noto-sans-korean-webfont-master/css/noto-sans-korean.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto Sans Korean", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
